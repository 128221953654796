import React from 'react';

const ProductButtonBackgroundIcon = ({ ...props }) => (
  <svg id="Component_1_1" dataname="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" width="190" height="29" viewBox="0 0 190 29" {...props}>
    <defs>
      <linearGradient id="linear-gradient" gradientUnits="objectBoundingBox">
        <stop offset="0.016" stopColor="#1b75bb"/>
        <stop offset="0.391" stopColor="#2b388f"/>
        <stop offset="0.503" stopColor="#2a368b"/>
        <stop offset="0.603" stopColor="#293180"/>
        <stop offset="0.698" stopColor="#27286e"/>
        <stop offset="0.749" stopColor="#262261"/>
      </linearGradient>
    </defs>
    <rect id="Rectangle_478" dataname="Rectangle 478" width="190" height="29" rx="14.5" transform="translate(190 29) rotate(180)" fill="url(#linear-gradient)"/>
    <path id="Path_3467" dataname="Path 3467" d="M810.281,945.465h0A12.435,12.435,0,0,1,797.846,957.9h-7.3c-12.888,0-15.036,0-16.11-2.763C769.266,941.839,753,933.03,753,933.03h44.846A12.435,12.435,0,0,1,810.281,945.465Z" transform="translate(-622.281 -930.965)" fill="#fff"/>
    <g id="Group_856" dataname="Group 856" transform="translate(-622.281 -930.965)">
      <g id="Group_855" dataname="Group 855">
        <path id="Path_3468" dataname="Path 3468" d="M790.3,949.8h0a.74.74,0,0,1,0-1.045l3.294-3.294-3.293-3.294a.738.738,0,0,1,0-1.045h0a.74.74,0,0,1,1.045,0l3.855,3.856a.683.683,0,0,1,0,.966l-3.855,3.856A.738.738,0,0,1,790.3,949.8Z" fill="#333"/>
      </g>
    </g>
    <g id="Group_858" dataname="Group 858" transform="translate(-622.281 -930.965)">
      <g id="Group_857" dataname="Group 857">
        <path id="Path_3469" dataname="Path 3469" d="M796.782,949.8h0a.74.74,0,0,1,0-1.045l2.588-2.587a1,1,0,0,0,0-1.414l-2.588-2.587a.74.74,0,0,1,0-1.045h0a.74.74,0,0,1,1.045,0l3.149,3.149a1.683,1.683,0,0,1,0,2.381l-3.149,3.148A.738.738,0,0,1,796.782,949.8Z" fill="#333"/>
      </g>
    </g>
  </svg>
);

export default ProductButtonBackgroundIcon;
