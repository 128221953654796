import { createContext } from 'react';

class SettingsService {
  static getValueForKey(key, settings) {
    if (!settings) {
      return null;
    }
    const setting = settings.find(({ id }) => id === key);
    if (!setting) {
      return null;
    }
    return setting.value;
  }
};

export const SettingsContext = createContext();

export default SettingsService;
