import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProductGridElement from 'Elements/ProductGridElement/ProductGridElement';

const ProductGrid = ({
  productList,
  category,
  shouldPresentMobile,
}) => { 
  
  const [sortedProductList, setSortedProductList] = useState([]);
  
  useEffect(() => {
    const sortedData = productList.sort((a, b) => {
      if (a.order === b.order) {
        return (a.name > b.name) ? 1 : -1;
      }
      return (a.order > b.order) ? 1 : -1;
    })
    setSortedProductList(sortedData);
  }, [productList]);
  
  return (
    <div className="product-grid">
      {category && (
        <div className="product-grid__header">
          <Link className="product-grid__header--title" to={`/category/${category.id}`}>
            {`${category.name} >>`}
          </Link>
          <div className="product-grid__header--description">
            {category.description}
          </div>
        </div>
      )}
      <div className="product-grid__list">
        {sortedProductList.map(product => <ProductGridElement key={product.id} product={product} categoryId={category ? category.id : null} shouldPresentMobile={shouldPresentMobile} />)}
      </div>
    </div>
  );
}
ProductGrid.propTypes = {
  productList: PropTypes.array,
  category: PropTypes.object,
  shouldPresentMobile: PropTypes.bool,
};

export default ProductGrid;
