import { 
  CATEGORY_LOADING,
  CATEGORY_SUCCESS,
  CATEGORY_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/actionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CATEGORY_SUCCESS:
      return {
        status: SUCCESS,
        data: payload,
      };
    case CATEGORY_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};
