import { baseUrl } from 'Constants';

export const callWebservice = ({ method, path }) => new Promise((resolve, reject) => {
  const url = `${baseUrl}${path}`;
  fetch(url, {
    method,
  }).then((response) => {
    if (!response.ok) {
      console.error(response);
      reject(new Error('Network response was not OK'))
      return;
    }
    resolve(response.json());
  }).catch((error) => {
    console.error(error);
    reject(error);
  });
});
