import { cloneDeep } from 'lodash';

class CategoryService {
  static getOrderedCategories = (structuredCategoriesArray) => {
    structuredCategoriesArray.forEach(category => {
      if (category.subCategories) {
        CategoryService.getOrderedCategories(category.subCategories)
      }
    });
    return structuredCategoriesArray.sort(({
      order: orderA,
    }, {
      order: orderB
    }) => orderA - orderB);
  };

  static parseCategories(categories) {
    let categoriesClone = cloneDeep(categories);
    categoriesClone = categoriesClone.filter(({ visibleFromMenu }) => visibleFromMenu === true);
    const structuredCategories = {};

    // Category array is transformed to an object where the keys are the category ids
    categoriesClone.forEach((category) => structuredCategories[category.id] = category);

    // Assigns the subCategories to the parent elements
    categoriesClone.forEach((category) => {
      if (category.parentCategories && category.parentCategories.length > 0) {
        category.parentCategories.forEach((parentCategoryId) => {
          if (structuredCategories[parentCategoryId]) {
            if (!structuredCategories[parentCategoryId].subCategories) {
              structuredCategories[parentCategoryId].subCategories = [];
            }
            structuredCategories[parentCategoryId].subCategories.push(category);
          }
        });
      }
    });

    // Removes the non main categories
    Object.keys(structuredCategories).forEach((categoryId) => {
      if (structuredCategories[categoryId].parentCategories && structuredCategories[categoryId].parentCategories.length > 0) {
        delete structuredCategories[categoryId];
      }
    });

    // Creates an array from structured categories object
    const structuredCategoriesArray = Object.keys(structuredCategories).map(categoryId => structuredCategories[categoryId]);
    const orderedCategories = CategoryService.getOrderedCategories(structuredCategoriesArray);

    return orderedCategories;
  }

  static getCategoryPathById(categoryId, categories, parentCategoryId) {
    const categoryPath = [];
    let currentCategoryId = categoryId;
    let currentParentCategoryId = parentCategoryId;
    let check = ({ id }) => currentCategoryId === id;

    while (currentCategoryId) {
      const category = categories.find(check);
      if (!category) {
        currentCategoryId = null;
      } else {
        categoryPath.push({
          id: category.id,
          name: category.name,
        });
        if (currentParentCategoryId) {
          currentCategoryId = currentParentCategoryId;
          currentParentCategoryId = null;
        } else {
          currentCategoryId = category.parentCategories ? category.parentCategories[0] : null;
        }
      }
    }
    return categoryPath.reverse();
  }
}

export default CategoryService;
