import React from 'react';
import PropTypes from 'prop-types';
import {useLocalized} from 'CustomHooks';

const CookiePopup = ({ consentAction }) => {
    return (
        <div className="cookiePopup">
            <div className="cookiePopup__title">{useLocalized('cookie_popup_title')}</div>
            <div className="cookiePopup__bottom">
                <div className="cookiePopup__desc">{useLocalized('cookie_popup_desc')}</div>
                <div className="cookiePopup__accept">
                    <button
                        className="cookiePopup__popupButton"
                        onClick={() => consentAction(false)}
                    >
                        {useLocalized('cookie_popup_accept')}
                    </button>
                </div>
            </div>
        </div>
    );
};

CookiePopup.propTypes = {
    consentAction: PropTypes.func
}

export default CookiePopup;
