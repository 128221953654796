import { map, assign, find } from 'lodash';

class ProductService {
  static addDetailToProduct(productId, details, products) {
    const product = products.find(({ id }) => id === productId);
    product.details = details;
    return products;
  }

  static addCategoryToProducts(products, categoryId) {
    const productsWithCategories = products.map((product) => ({ ...product, categoryId }));
    return productsWithCategories;
  }

  static mergeProductsById(payload, products) {
    const { products: newProducts, categoryId } = payload;
    let newProductsWithCategoryIds = newProducts;
    if (categoryId) {
      newProductsWithCategoryIds = ProductService.addCategoryToProducts(newProducts, categoryId);
    }
    const mergedProducts = map(newProductsWithCategoryIds, (newProduct) => {
      return assign(find(products, { id: newProduct.id }), newProduct);
    });
    return mergedProducts;
  }
}

export default ProductService;
