import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWebService, useLocalized } from 'CustomHooks';
import { getJobs } from 'Redux/Actions';
import { carrier_page_img } from 'Assets';
import { jobApplicationMailAddress } from 'Constants';
import { LOADING, SUCCESS } from 'Redux/actionTypes';
import Spinner from 'Elements/Spinner/Spinner';
import JobSelector from 'Components/JobSelector/JobSelector';

const Jobs = ({
  jobs: {
    status: jobsStatus,
    data: jobsData,
  },
  getJobs,
}) => {
  useWebService(jobsStatus, getJobs);
  const handleApplication = useCallback(() => {
    window.location.href = `mailto: ${jobApplicationMailAddress}`;
  }, []);

  return (
    <div className="jobs-page">
      <div className="jobs-page__section top">
        <div className="jobs-page__section--title">
          {useLocalized('carrier_page_title_1')}
        </div>
      </div>
      <div className="jobs-page__image-section">
        <img src={carrier_page_img} className="jobs-page__image-section--image" alt="jobs" />
        <button onClick={handleApplication} className="jobs-page__image-section--button">
          {useLocalized('apply_now')}
        </button>
      </div>
      <div className="jobs-page__section">
        <div className="jobs-page__section--title">
          {useLocalized('carrier_page_title_2')}
        </div>
        <div className="jobs-page__section--text">
          {useLocalized('carrier_page_text_2')}
        </div>
      </div>
      {jobsStatus === LOADING && <Spinner />}
      {jobsStatus === SUCCESS && jobsData.length > 0 && <JobSelector jobs={jobsData} />}
    </div>
  );
};

Jobs.propTypes = {
  jobs: PropTypes.object,
  getJobs: PropTypes.func,
};

export default connect(({
  jobs,
}) => ({
  jobs,
}), {
  getJobs,
})(Jobs);
