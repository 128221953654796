import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideAdBar from 'Components/AdBar/SideAdBar';
import { useCategoryAdSelectionLogic } from 'CustomHooks';
import { leftAdCategoryId } from 'Constants';

const LeftAdsSection = ({
  categories: {
    data: categoryData,
  },
}) => {
  const [currentCategories, categoryId] = useCategoryAdSelectionLogic(leftAdCategoryId, categoryData);

  return (
    <div className="left-ads-section">
      {currentCategories.map(({ id, name, logo }) => (
        <SideAdBar
          key={id}
          id={id}
          title={name}
          parentCategoryId={categoryId}
          backgroundImage={logo}
        />
      ))}
    </div>
  );
};

LeftAdsSection.propTypes = {
  withTitle: PropTypes.bool,
  categories: PropTypes.object,
};

export default connect(({
  categories,
}) => ({
  categories,
}), null)(LeftAdsSection);
