import React, { useRef } from 'react';
import { useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export const CategoryTitleRefContext = createContext();

// When react router changes path, the page is scrolled to the top or to the product grid top
const ScrollToTop = ({ children, location }) => {
  const categoryTitleRef = useRef();

  useEffect(() => {
    
    // Scrolling has to be in the event loop. When the menu dissapears the position of the categoryTitle is changing in some cases
    setTimeout(() => {
      if (categoryTitleRef.current) {
        window.scrollTo({
          behavior: "smooth",
          top: categoryTitleRef.current.offsetTop,
        });
        return;
      }
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  return (
    <CategoryTitleRefContext.Provider value={categoryTitleRef}>
      {children}
    </CategoryTitleRefContext.Provider>
  );
};

ScrollToTop.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  location: PropTypes.object,
};

export default withRouter(ScrollToTop);
