class ContactService {
  static agentContactsToCountyContacts(agents) {
    const countyContacts = [];
    agents.forEach(({
      name: agentName,
      counties,
      formattedPhone,
      phone,
      email,
      description,
      default: defaultContact,
    }) => {
      if (counties.length > 0) {
        counties.forEach(({
          id,
          name: countyName,
        }) => {
          const countyContact = {
            id,
            countyName,
            formattedPhone,
            phone,
            email,
            agentName,
            description,
            defaultContact,
          };
          countyContacts.push(countyContact);
        });
      } else {
        countyContacts.push({
          agentName,
          formattedPhone,
          phone,
          email,
          description,
          defaultContact,
        });
      }
    });
    return countyContacts;
  }
}

export default ContactService;
