import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LinkLabel from 'Elements/LinkLabel/LinkLabel';
import LinkImage from 'Elements/LinkImage/LinkImage';
import LinkWithState from 'Elements/LinkWithState/LinkWithState';
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import { useLocalized } from 'CustomHooks';
import { FACEBOOK_PAGE_URL } from 'Constants/companyOptions';
import { facebook_logo_gray } from 'Assets';

const MobileMenu = ({
  structuredCategories,
}) => {

  // Prevents the user from scrolling while the mobile menu is open
  useEffect(() => {
    window.document.body.setAttribute('style', 'overflow: hidden');
    return () => {
      window.document.body.removeAttribute('style');
    };
  }, []);

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__top-nav">
        {structuredCategories.map(({
          id,
          logo,
          name,
          subCategories,
        }) => (
          <ExpansionPanel
            key={id}
            title={(
              <div className="mobile-menu__top-nav--element-title">
                <div className="mobile-menu__top-nav--element-title--logo">
                  <img className="category-logo-image" src={logo} alt={name} />
                </div>
                <div className="mobile-menu__top-nav--element-title--title">
                  {name}
                </div>
              </div>
            )}
          >
            <div className="mobile-menu__top-nav--container">
              {subCategories.map(({
                id: parentCategoryId,
                name,
                subCategories: subSubCategories,
              }) => (
                <div key={parentCategoryId} className="mobile-menu__top-nav--element-content">
                  <Link className="mobile-menu__top-nav--element-content--title" to={`/category/${parentCategoryId}`}>
                    {name}
                  </Link>
                  <div className="mobile-menu__top-nav--element-content--elements">
                    {subSubCategories && subSubCategories.map(({
                      id,
                      name,
                    }) => (
                      <LinkWithState key={id} className="mobile-menu__top-nav--element-content--elements--link" to={`/category/${id}`} state={{ parentCategoryId }}>
                        {name}
                      </LinkWithState>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </ExpansionPanel>
        ))}
      </div>
      <div className="mobile-menu__bottom-nav">
        <LinkLabel className="mobile-menu__bottom-nav--elements" id="carier-label-id" text={useLocalized('jobs')} link="/cariere"/>
        <LinkLabel className="mobile-menu__bottom-nav--elements" id="contact-label-id" text={useLocalized('contact')} link="/contact"/>
        <LinkLabel className="mobile-menu__bottom-nav--elements" link="/" id="carier-label-id" text={useLocalized('about_us')} />
        <LinkLabel className="mobile-menu__bottom-nav--elements" link="/" id="carier-label-id" text={useLocalized('terms_and_conditions')} />
        <LinkLabel className="mobile-menu__bottom-nav--elements" link="/" id="carier-label-id" text={useLocalized('contact')} />
        <LinkLabel className="mobile-menu__bottom-nav--elements" link="/" id="carier-label-id" text={useLocalized('cookie_policy')} />
        <LinkImage
          className="mobile-menu__bottom-nav--elements image"
          id="fb-image-id"
          link={FACEBOOK_PAGE_URL}
          imageSrc={facebook_logo_gray}
          newTab
        />
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  structuredCategories: PropTypes.array,
};

export default MobileMenu;
