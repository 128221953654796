import React, {useMemo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import HomePage from 'Screens/HomePage/HomePage';
import ProductPage from 'Screens/ProductPage/ProductPage';
import Jobs from 'Screens/Jobs/Jobs';
import AboutUsPage from 'Screens/AboutUsPage/AboutUsPage';
import CookiesPolicyPage from 'Screens/CookiesPolicyPage/CookiesPolicyPage';
import TermsAndConditions from 'Screens/TermsAndConditions/TermsAndConditionsPage';
import TopNavBar from 'Components/TopNavBar/TopNavBar';
import Footer from 'Components/Footer/Footer';
import CookiePopup from 'Components/CookiePopup/CookiePopup';
import ScrollToTop from 'Elements/ScrollToTop/ScrollToTop';
import { useWebService, useWindowWidth } from 'CustomHooks';
import { breakpoints } from 'Constants';
import { getCategories, getSettings, getBrands } from 'Redux/Actions';
import { LocalizedContext } from 'Services/LocalizationService';
import { SettingsContext } from 'Services/SettingsService';
import { CategoryService } from 'Services';
import { SUCCESS } from 'Redux/actionTypes';

import 'main.scss';
import configureGTM from "Data/GTMConfig";

const App = ({
  user: {
    locale,
  },
  categories: {
    status: categoryStatus,
    data: categoryData,
  },
  settings: {
    status: settingsStatus,
    data: settingsData,
  },
  brands: {
    status: brandsStatus,
  },
  getCategories,
  getSettings,
  getBrands,
}) => {
  useWebService(categoryStatus, getCategories);
  useWebService(settingsStatus, getSettings);
  useWebService(brandsStatus, getBrands);
  useEffect(() => configureGTM(), []);
  const [shouldPresentMobile, windowWidth] = useWindowWidth(breakpoints.md);

  const structuredCategories = useMemo(() => {
    if (categoryStatus !== SUCCESS) {
      return [];
    }
    return CategoryService.parseCategories(categoryData)
  }, [categoryData, categoryStatus]);

  const [cookiePopupPresented, setCookiePopupPresented] = useState(true);

  return (
    <LocalizedContext.Provider value={locale}>
    <SettingsContext.Provider value={settingsData}>
      <Helmet htmlAttributes={{ lang: locale.replace('_', '-') }} />
      <Router>
        <ScrollToTop>
          <TopNavBar structuredCategories={structuredCategories} shouldPresentMobile={shouldPresentMobile} />

          <Route path="/" exact render={() => <HomePage structuredCategories={structuredCategories} shouldPresentMobile={shouldPresentMobile} windowWidth={windowWidth} />} />
          <Route path="/category/:categoryId" exact render={() => <HomePage structuredCategories={structuredCategories} shouldPresentMobile={shouldPresentMobile} />} windowWidth={windowWidth} />
          <Route path="/search" exact render={() => <HomePage structuredCategories={structuredCategories} shouldPresentMobile={shouldPresentMobile} windowWidth={windowWidth} />} />
          <Route path="/category/:categoryId/product/:productId" exact component={ProductPage} />
          <Route path="/product/:productId" exact component={ProductPage} />
          <Route path="/cariere" exact component={Jobs} />
          <Route path="/despre-noi" exact render={() => <AboutUsPage structuredCategories={structuredCategories}/>} />
          <Route path="/cookies-policy" exact render={() => <CookiesPolicyPage />} />
          <Route path="/terms-and-conditions" exact render={() => <TermsAndConditions/>} />

          <Footer />
        </ScrollToTop>
      </Router>
      {cookiePopupPresented && <CookiePopup consentAction={setCookiePopupPresented} />}
    </SettingsContext.Provider>
    </LocalizedContext.Provider>
  );
}

App.propTypes = {
  user: PropTypes.object,
  categories: PropTypes.object,
  getCategories: PropTypes.func,
  settings: PropTypes.object,
  getSettings: PropTypes.func,
  brands: PropTypes.object,
  getBrands: PropTypes.func,
}

export default connect(({
  user,
  categories,
  settings,
  brands,
}) => ({
  user,
  categories,
  settings,
  brands,
}), {
  getCategories,
  getSettings,
  getBrands,
})(App);
