import { useEffect } from 'react';
import { DEFAULT } from 'Redux/actionTypes';

export const useWebService = (status, webService) => {
  useEffect(() => {
    if (status === DEFAULT) {
      webService();
    }
  }, [status, webService]);
};

export const useWebServiceWithId = (status, webService, id) => {
  useEffect(() => {
    if (!id) {
      return;
    }
    webService(id);
  }, [id, webService]);
};
