import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProductPathBar = ({ categoryPath }) => (
  <div className="category-path-bar">
    {categoryPath.map(({
      id,
      name,
      isSearch,
    }, index) => (
      React.createElement(
        index === 0 && !isSearch ? 'span' : Link,
        index === 0 && !isSearch ? { key: id, className:"category-path-bar__element" } : { key: id, className: "category-path-bar__element", to: `${isSearch ? `/${id}` : `/category/${id}`}` },
        index !== categoryPath.length - 1 ? `${name} / `: name,
      )
    ))}
  </div>
);

ProductPathBar.propTypes = {
  categoryPath: PropTypes.array,
};

export default ProductPathBar;
