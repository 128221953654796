import { useState, useEffect, useCallback } from 'react';

const useWindowWidth = (maxMobileWidth) => {
  const [shouldPresentMobile, setShouldPresentMobile] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState();

  const handleResize = useCallback(() => {
    setShouldPresentMobile(window.innerWidth <= maxMobileWidth);
    setWindowWidth(window.innerWidth)
  }, [maxMobileWidth]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return [shouldPresentMobile, windowWidth];
};

export default useWindowWidth;
