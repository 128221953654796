import { unionBy } from 'lodash';

class BrandService {
  static mergeBrandsFromProducts(products, brands) {
    const brandsFromProducts = products.map(({ brand }) => brand);
    const mergedBrands = unionBy([...brandsFromProducts, ...brands], 'id');
    return mergedBrands;
  }
}

export default BrandService;
