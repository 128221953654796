import React from 'react'
import PropTypes from 'prop-types';
import { useLocalized } from 'CustomHooks';

const CategoryLabel = ({
  setIsMenuPresented,
  shouldPresentMobile,
  isMobileMenuPresented,
}) => {
  const productCategoriesText = useLocalized('product_categories');
  return (
    <div className="category-label-container">
      <div
        id="category-label-id"
        to="/"
        className="category-label"
        onMouseOver={() => {
          if (shouldPresentMobile) {
            return;
          }
          setIsMenuPresented()
        }}
        onClick={() => setIsMenuPresented()}
      >
        {shouldPresentMobile ? (
          <>
            <div className={`category-label__hamb-menu-element ${isMobileMenuPresented ? "open" : ""}`} />
            <div className={`category-label__hamb-menu-element ${isMobileMenuPresented ? "open" : ""}`} />
            <div className={`category-label__hamb-menu-element ${isMobileMenuPresented ? "open" : ""}`} />
          </>
        ) : `${productCategoriesText}`}
      </div>
    </div>
  );
}

CategoryLabel.propTypes = {
  categories: PropTypes.object,
  setIsMenuPresented: PropTypes.func,
  shouldPresentMobile: PropTypes.bool,
  isMobileMenuPresented: PropTypes.bool,
};

export default CategoryLabel;
