import { combineReducers } from 'redux';
import UserReducer from 'Redux/Reducers/UserReducer';
import CategoryReducer from 'Redux/Reducers/CategoryReducer';
import ContactReducer from 'Redux/Reducers/ContactReducer';
import ProductReducer from 'Redux/Reducers/ProductReducer';
import SettingsReducer from 'Redux/Reducers/SettingsReducer';
import BrandsReducer from 'Redux/Reducers/BrandsReducer';
import BannerReducer from 'Redux/Reducers/BannerReducer';
import JobsReducer from 'Redux/Reducers/JobsReducer';

export default combineReducers({
  user: UserReducer,
  categories: CategoryReducer,
  contacts: ContactReducer,
  products: ProductReducer,
  settings: SettingsReducer,
  brands: BrandsReducer,
  banners: BannerReducer,
  jobs: JobsReducer,
});
