import { callWebservice } from 'Controllers/WebController';
import { 
  SETTINGS_LOADING,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL
} from 'Redux/actionTypes';

export const getSettings = () => async (dispatch) => {
  dispatch({ type: SETTINGS_LOADING });
  try {
      let options = {
          method: 'GET',
          path: '/setting',
      };
  
      const settings = await callWebservice(options);
      dispatch({
        type: SETTINGS_SUCCESS,
        payload: settings,
      });
  } catch(err) {
      dispatch({ type: SETTINGS_FAIL });
  }
};
