import React from 'react';
import { useLocalized } from 'CustomHooks';

const TermsAndConditionsPage = () => {
  return (
    <div className="about-us-page__section top">
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_1')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_2')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_3')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_4')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_5')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_6')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_7')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_8')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_9')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_10')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_11')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_12')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_13')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_14')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_15')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_16')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_17')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_18')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_19')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_20')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_21')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_22')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_23')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_24')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_25')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_26')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_27')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_28')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_29')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_30')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_31')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_32')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_33')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_34')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_35')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_36')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_37')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_38')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_39')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_40')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_41')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_42')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_43')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_44')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_45')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_46')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_47')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_48')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_49')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_50')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_51')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_52')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_53')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_54')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_55')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_56')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_57')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_58')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_59')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_60')}
      </div>
      <div className="about-us-page__section--text">
        {useLocalized('terms_and_conditions_text_61')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_62')}
      </div>
      <div className="about-us-page__section--title-1">
        {useLocalized('terms_and_conditions_text_63')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_64')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_65')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_66')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_67')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_68')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_69')}
      </div>
      <div className="about-us-page__section--regular-highlighted-text">
        {useLocalized('terms_and_conditions_text_70')}
      </div>
    </div>
  )
}

export default TermsAndConditionsPage;
