import { callWebservice } from 'Controllers/WebController';
import { 
  BRANDS_LOADING,
  BRANDS_SUCCESS,
  BRANDS_FAIL
} from 'Redux/actionTypes';

export const getBrands = () => async (dispatch) => {
  dispatch({ type: BRANDS_LOADING });
  try {
      let options = {
          method: 'GET',
          path: '/brand/?highlighted=true',
      };
  
      const settings = await callWebservice(options);
      dispatch({
        type: BRANDS_SUCCESS,
        payload: settings,
      });
  } catch(err) {
      dispatch({ type: BRANDS_FAIL });
  }
};
