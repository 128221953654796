import { 
  BANNERS_LOADING,
  BANNERS_SUCCESS,
  BANNERS_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/actionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case BANNERS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case BANNERS_SUCCESS:
      return {
        status: SUCCESS,
        data: payload,
      };
    case BANNERS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};
