import React from 'react';

const PhoneIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 26.52 25.9">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <rect x="6.65" width="13.22" height="25.9" rx="0.92" fill="#2a3588"/>
                    <rect x="7.62" y="2.07" width="11.29" height="20.86" rx="0.92" fill="#fff"/>
                    <g>
                        <path d="M22,8.54a4.89,4.89,0,0,1,.23,7.61" fill="none" stroke="#2a3588" stroke-miterlimit="10"
                              stroke-width="0.6"/>
                        <path d="M23.28,6.57a7.42,7.42,0,0,1,.35,11.55" fill="none" stroke="#2a3588"
                              stroke-miterlimit="10" stroke-width="0.6"/>
                    </g>
                    <g>
                        <path d="M4.55,8.54a4.89,4.89,0,0,0-.23,7.61" fill="none" stroke="#2a3588"
                              stroke-miterlimit="10" stroke-width="0.6"/>
                        <path d="M3.24,6.57a7.42,7.42,0,0,0-.35,11.55" fill="none" stroke="#2a3588"
                              stroke-miterlimit="10" stroke-width="0.6"/>
                    </g>
                    <path
                        d="M16,1.19H10.51A.21.21,0,0,1,10.3,1h0a.21.21,0,0,1,.21-.21H16a.21.21,0,0,1,.22.21h0A.22.22,0,0,1,16,1.19Z"
                        fill="#fff"/>
                    <path d="M14.06,24.44a.79.79,0,1,1-.79-.79A.79.79,0,0,1,14.06,24.44Z" fill="#fff"/>
                </g>
            </g>
        </g>
    </svg>
);

export default PhoneIcon;
