// Category action types
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const CATEGORY_FAIL = 'CATEGORY_FAIL';

// Product action types
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAIL = 'PRODUCTS_FAIL';
export const PRODUCTS_BY_CATEGORY_LOADING = 'PRODUCTS_BY_CATEGORY_LOADING';
export const PRODUCTS_BY_CATEGORY_SUCCESS = 'PRODUCTS_BY_CATEGORY_SUCCESS';
export const PRODUCTS_BY_CATEGORY_FAIL = 'PRODUCTS_BY_CATEGORY_FAIL';
export const PRODUCT_BY_ID_LOADING = 'PRODUCT_BY_ID_LOADING';
export const PRODUCT_BY_ID_SUCCESS = 'PRODUCT_BY_ID_SUCCESS';
export const PRODUCT_BY_ID_FAIL = 'PRODUCT_BY_ID_FAIL';
export const PRODUCT_DETAIL_BY_ID_LOADING = 'PRODUCT_DETAIL_BY_ID_LOADING';
export const PRODUCT_DETAIL_BY_ID_SUCCESS = 'PRODUCT_DETAIL_BY_ID_SUCCESS';
export const PRODUCT_DETAIL_BY_ID_FAIL = 'PRODUCT_DETAIL_BY_ID_FAIL';
export const PRODUCTS_BY_SEARCHTEXT_LOADING = 'PRODUCTS_BY_SEARCHTEXT_LOADING';
export const PRODUCTS_BY_SEARCHTEXT_SUCCESS = 'PRODUCTS_BY_SEARCHTEXT_SUCCESS';
export const PRODUCTS_BY_SEARCHTEXT_FAIL = 'PRODUCTS_BY_SEARCHTEXT_FAIL';

// Settings action types
export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAIL = 'SETTINGS_FAIL';

// Contact action types
export const CONTACTS_LOADING = 'CONTACTS_LOADING';
export const CONTACTS_SUCCESS = 'CONTACTS_SUCCESS';
export const CONTACTS_FAIL = 'CONTACTS_FAIL';

// Brands action types
export const BRANDS_LOADING = 'BRANDS_LOADING';
export const BRANDS_SUCCESS = 'BRANDS_SUCCESS';
export const BRANDS_FAIL = 'BRANDS_FAIL';
export const MERGE_BRANDS_FROM_PRODUCTS = 'MERGE_BRANDS_FROM_PRODUCTS';

// Banner action types
export const BANNERS_LOADING = 'BANNERS_LOADING';
export const BANNERS_SUCCESS = 'BANNERS_SUCCESS';
export const BANNERS_FAIL = 'BANNERS_FAIL';

// Job action types
export const JOBS_LOADING = 'JOBS_LOADING';
export const JOBS_SUCCESS = 'JOBS_SUCCESS';
export const JOBS_FAIL = 'JOBS_FAIL';

// Generic action types
export const DEFAULT = 'DEFAULT';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
