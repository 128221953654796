import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImagePresenter from 'Elements/ImagePresenter/ImagePresenter';
import { useDragLogic } from 'CustomHooks';

const ProductImageViewerBot = ({ images, imgClickHandler, productName }) => {
  const [ref, onMouseMove, onMouseDown, onMouseUp, preventDragHandler] = useDragLogic();
  const [isOverFlowing, setIsOverFlowing] = useState(false);
  const [sortedImages, setSortedImages] = useState([]);
  
  useEffect(() => {

    if (ref.current) {
      setIsOverFlowing(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);
  
  useEffect(() => {
    const sortedImages = images.sort((a, b) => (a.order > b.order) ? 1 : -1)
    setSortedImages(sortedImages);
  }, [images]);

  return (
    <div
      className={`image-viewer-bottom ${isOverFlowing ? 'overflowing' : ''}`}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {sortedImages.map(({ id, image }) => {
        return (
          <div className="image-viewer-bottom__element" key={id} onClick={() => imgClickHandler(image)}>
            <ImagePresenter
              src={image}
              alt={productName}
              onDragStart={preventDragHandler}
            />
          </div>
        );
      })}
    </div>
  );
}

ProductImageViewerBot.propTypes = {
  images: PropTypes.array,
  imgClickHandler: PropTypes.func,
  productName: PropTypes.string,
}

export default ProductImageViewerBot;
