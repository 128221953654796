import { createContext } from 'react';
import romanianText from 'Data/Magdolna_Romanian.json'
import { supportedLocales } from 'Constants';

class LocalizationService {
  static getCurrentTranslations = (locale) => {
    switch (locale) {
      case supportedLocales.ro_RO:
        return romanianText;
      default:
        return romanianText;
    }
  }

  static getLocalizedText(locale, text) {
    const currentTranslations = this.getCurrentTranslations(locale);
    if (!currentTranslations[text]) {
      return text;
    }
    return currentTranslations[text];
  }
}

export const LocalizedContext = createContext();

export default LocalizationService;
