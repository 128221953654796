import React from 'react';
import PropTypes from 'prop-types';

const CategoryBar = ({ categoryItems, onCategorySelection }) => {
  return (
    <div className="category-bar" id='category-bar'>
      { categoryItems.map(({
        id,
        name,
        logo,
      }) => (
        <div key={id} className="category-bar__item" onMouseOver={() => onCategorySelection(id)}>
          <div className="category-bar__item--logo">
            {logo && <img src={logo} alt="category-logo" className="category-bar__item--logo--image"/>}
          </div>
          <div className="category-bar__item--title">
            {name}
          </div>
        </div>
      ))}
    </div>
    );
  }

CategoryBar.propTypes = {
  categoryItems: PropTypes.array,
  onCategorySelection: PropTypes.func,
};

export default CategoryBar;
