import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ClickOutsideAlerter = ({ children, handleOutsideClick }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        return;
      }
      handleOutsideClick();
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleOutsideClick]);

  return (
    <div ref={wrapperRef}>
      {children}
    </div>
  );
};

ClickOutsideAlerter.propTypes = {
  children: PropTypes.element,
  handleOutsideClick: PropTypes.func,
};

export default ClickOutsideAlerter;
