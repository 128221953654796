import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, hasIn } from 'lodash';
import { useWebService } from 'CustomHooks';
import { getContacts } from 'Redux/Actions';
import RomaniaMap from 'Elements/RomaniaMap/RomaniaMap';
import Spinner from 'Elements/Spinner/Spinner';
import LinkLabel from 'Elements/LinkLabel/LinkLabel';
import DepartmentContact from 'Elements/DepartmentContact/DepartmentContact';
import ContactDetailsElement from 'Components/Footer/ContactDetailsElement';
import { 
  LOADING,
  SUCCESS,
} from 'Redux/actionTypes';
import { staticCompanyContacts } from 'Constants';
import { useLocalized } from 'CustomHooks';
import ClickOutsideAlerter from 'Components/ClickOutsideAlerter/ClickOutsideAlerter';

const Footer = ({
  contacts: {
    status: contactStatus,
    data: contactData,
  },
  getContacts,
}) => {
  useWebService(contactStatus, getContacts);
  const [currentContact, setCurrentContact] = useState(null);

  // Sets the default contact info
  useEffect(() => {
    if (contactData.length > 0 && !currentContact) {
      const contacts = contactData.filter(({ defaultContact }) => defaultContact);
      setCurrentContact(contacts);
    }
  }, [contactData, currentContact]);

  const handleMapClick = useCallback((e) => {
    if (!hasIn(e, 'target.attributes.id.value')) {
      return;
    }
    const countyId = parseInt(get(e, 'target.attributes.id.value'));
    
    // If a county is deselected the default contact is presented
    if (currentContact && countyId === currentContact[0].id) {
      setCurrentContact(null)
      return;
    }
    const contactForCountryId = contactData.find(contact => contact.id === countyId);
    setCurrentContact([contactForCountryId]);
  }, [contactData, currentContact]);

  const handleOutsideMapClick = useCallback(() => {
    setCurrentContact(null);
  }, []);

  return (
    <div className="footer">
      <div className="footer__contact-details">
        <div className="footer__contact-details--element contact-holder">
          {contactStatus === LOADING && <Spinner />}
          {contactStatus === SUCCESS && currentContact && (
              <ContactDetailsElement currentContact={currentContact[0]}/>
          )}
          {contactStatus === SUCCESS && currentContact && currentContact.length > 1 && (
              <ContactDetailsElement currentContact={currentContact[1]}/>
          )}
        </div>
        <div className="footer__contact-details--element">
          <ClickOutsideAlerter handleOutsideClick={handleOutsideMapClick}>
            <RomaniaMap onClick={handleMapClick} selectedCountyId={currentContact ? currentContact[0].id : 0} />
          </ClickOutsideAlerter>
        </div>
      </div>
      <div className="footer__departments">
        {staticCompanyContacts.map(({ id, ...contactData }) => <DepartmentContact key={id} contact={contactData} /> )}
      </div>
      <div className="footer__links">
        <LinkLabel className="footer__links--element"  link="/despre-noi" id="carier-label-id" text={useLocalized('about_us')} />
        <LinkLabel className="footer__links--element"  link="/terms-and-conditions" id="carier-label-id" text={useLocalized('terms_and_conditions')} />
        <LinkLabel className="footer__links--element"  link="/contact" id="carier-label-id" text={useLocalized('contact')} />
        <LinkLabel className="footer__links--element"  link="/cookies-policy" id="carier-label-id" text={useLocalized('cookie_policy')} />
      </div>
      <div className="footer__bottom">
        {useLocalized('copy_right_text')}
      </div>
    </div>
  );
};

Footer.propTypes = {
  contacts: PropTypes.object,
  getContacts: PropTypes.func,
}

export default connect(({
  contacts,
}) => ({
  contacts,
}), {
  getContacts,
})(Footer);
