import { 
  useRef,
  useState,
  useCallback,
} from 'react';

const useDragLogic = () => {
  const ref = useRef(null);
  const previousScrollPosition = useRef(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const onMouseDown = useCallback((e) => {
    setIsScrolling(true);
    previousScrollPosition.current = e.clientX; 
  }, []);

  const onMouseMove = (e) => {
    if (isScrolling) {
      ref.current.scrollLeft = ref.current.scrollLeft - e.clientX + previousScrollPosition.current;
      previousScrollPosition.current = e.clientX;
    }
  };

  const onMouseUp = useCallback(() => {
    setIsScrolling(false);
  }, []);

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsScrolling(false);
  }, []);

  return [ref, onMouseMove, onMouseDown, onMouseUp, preventDragHandler, onMouseLeave];
};

export default useDragLogic;
