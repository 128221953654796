import { ProductService } from 'Services';
import {
  PRODUCTS_LOADING,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCTS_BY_CATEGORY_LOADING,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
  PRODUCT_BY_ID_LOADING,
  PRODUCT_BY_ID_SUCCESS,
  PRODUCT_BY_ID_FAIL,
  PRODUCT_DETAIL_BY_ID_LOADING,
  PRODUCT_DETAIL_BY_ID_SUCCESS,
  PRODUCT_DETAIL_BY_ID_FAIL,
  PRODUCTS_BY_SEARCHTEXT_LOADING,
  PRODUCTS_BY_SEARCHTEXT_SUCCESS,
  PRODUCTS_BY_SEARCHTEXT_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/actionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCTS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case PRODUCTS_SUCCESS:
      return {
        status: SUCCESS,
        data: payload,
      };
    case PRODUCTS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case PRODUCTS_BY_CATEGORY_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case PRODUCTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: ProductService.mergeProductsById(payload, state.data),
      };
    case PRODUCTS_BY_CATEGORY_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case PRODUCT_BY_ID_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: ProductService.mergeProductsById(payload, state.data),
      };
    case PRODUCT_BY_ID_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case PRODUCT_DETAIL_BY_ID_LOADING:
      return {
        ...state,
        status: PRODUCT_DETAIL_BY_ID_LOADING,
      };
    case PRODUCT_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        status: PRODUCT_DETAIL_BY_ID_SUCCESS,
        data: ProductService.addDetailToProduct(payload.productId, payload.details, state.data),
      };
    case PRODUCT_DETAIL_BY_ID_FAIL:
      return {
        ...state,
        status: PRODUCT_DETAIL_BY_ID_FAIL,
      };
    case PRODUCTS_BY_SEARCHTEXT_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case PRODUCTS_BY_SEARCHTEXT_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: ProductService.mergeProductsById(payload, state.data),
      };
    case PRODUCTS_BY_SEARCHTEXT_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};
