import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDragLogic } from 'CustomHooks';

const JobsSelector = ({
  jobs,
}) => {
  const [ref, onMouseMove, onMouseDown, onMouseUp, preventDragHandler, onMouseLeave] = useDragLogic();
  const [currentJob, setCurrentJob] = useState(jobs[0]);
  const handleJobSelection = useCallback((selectedJobId) => {
    const jobForId = jobs.find(({ id }) => id === selectedJobId);
    setCurrentJob(jobForId);
  }, [jobs]);
  const { id: currentJobId, title, description } = currentJob;

  const [isOverFlowing, setIsOverFlowing] = useState(false);
  useEffect(() => {
    if (ref.current) {
      setIsOverFlowing(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <div className="job-selector">
      <div
        className={`job-selector__selection-bar ${isOverFlowing ? "overflowing" : ""}`}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {jobs.map(({ id, title, logo }) => (
          <div
            key={id}
            onClick={() => handleJobSelection(id)}
            className={`job-selector__selection-bar--element ${currentJobId === id ? 'selected' : ''}`}
            onDragStart={preventDragHandler}
          >
            <div className="bar-element-logo">
              <img className="bar-element-logo__image" src={logo} alt="job" />
            </div>
            <div className="bar-element-title">
              {title}
            </div>
          </div>
        ))}
      </div>
      <div className="job-selector__content-area">
        <div className="job-selector__content-area--title">{title}</div>
        <div className="job-selector__content-area--description" dangerouslySetInnerHTML={{ __html: description }}>
        </div>
      </div>
    </div>
  );
};

JobsSelector.propTypes = {
  jobs: PropTypes.array,
};

export default JobsSelector;
