import { callWebservice } from 'Controllers/WebController';
import { 
  CONTACTS_LOADING,
  CONTACTS_SUCCESS,
  CONTACTS_FAIL,
} from 'Redux/actionTypes';

export const getContacts = () => async (dispatch) => {
  dispatch({ type: CONTACTS_LOADING });
  try {
      let options = {
          method: 'GET',
          path: '/agent',
      };
  
      const contacts = await callWebservice(options);
      dispatch({
        type: CONTACTS_SUCCESS,
        payload: contacts,
      });
  } catch(err) {
      dispatch({ type: CONTACTS_FAIL });
  }
};
