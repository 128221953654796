import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import ThreeAdsSection from 'Components/ThreeAdsSection/ThreeAdsSection';
import ProductPathBar from 'Elements/ProductPathBar/ProductPathBar';
import ProductDetails from 'Components/ProductDetails/ProductDetails';
import Spinner from 'Elements/Spinner/Spinner';
import ProductImageViewer from 'Elements/ProductImageViewer/ProductImageViewer';
import HTMLStringParser from 'Elements/HTMLStringParser/HTMLStringParser';
import { CategoryService } from 'Services';
import { getProductById, getProductDetailsById } from 'Redux/Actions';
import { LOADING, PRODUCT_DETAIL_BY_ID_LOADING } from 'Redux/actionTypes';
import { useLocalized } from 'CustomHooks';

const ProductPage = ({
  products: {
    status: productStatus,
    data: productData,
  },
  categories: {
    data: categoryData,
  },
  getProductById,
  getProductDetailsById,
  match: {
    params: {
      productId,
      categoryId,
    },
  },
  history: {
    location: {
      state,
    }
  },
}) => {
  const [currentProduct, setCurrentProduct] = useState(null);
  useEffect(() => {
    const product = productData.find(({ id }) => id === productId);
    if (!product) {
      getProductById(productId, categoryId);
      return;
    }
    setCurrentProduct(product);
  }, [categoryId, getProductById, productData, productId]);

  useEffect(() => {
    if (currentProduct && !currentProduct.details) {
      getProductDetailsById(productId);
    }
  }, [currentProduct, getProductDetailsById, productId]);

  const searchResultsPageTitle = useLocalized('search_page');
  const [categoryPath, setCategoryPath] = useState([]);
  useEffect(() => {
    const searchParams = state ? state.search : null;
    if (searchParams) {
      setCategoryPath([{
        id: `search${searchParams}`,
        name: searchResultsPageTitle,
        isSearch: true, // Tells the ProductProductPath to place an active link
      }]);
      return;
    }
    const parentCategoryId = state ? state.parentCategoryId : null;
    if (categoryData.length > 0) {
      const categoryPathForProductsAccessedFromCategoryPage = CategoryService.getCategoryPathById(categoryId, categoryData, parentCategoryId);
      setCategoryPath(categoryPathForProductsAccessedFromCategoryPage);
    }
  }, [categoryData, categoryId, searchResultsPageTitle, state]);

  return (
    <div className="product-page">
      {productStatus === LOADING && <Spinner /> }
      {currentProduct && (
        <>
          <Helmet title={currentProduct.name} />
          <div className="product-page__top-section">
            {categoryPath && <ProductPathBar categoryPath={categoryPath} />}
            <div className="product-page__top-section--title">
              {currentProduct.name}
            </div>
            <div className="product-page__top-section--data">
              {currentProduct.images.length > 0 && (
                <div className="data-left">
                  <ProductImageViewer className={"product-image-viewer-box"} images={currentProduct.images} productName={currentProduct.name} />
                </div>
              )}
              <div className="data-right">
                <div className="data-right__brand">
                  <img className="data-right__brand--image" src={currentProduct.brand.logo} alt={currentProduct.brand.name} />
                </div>
                <div className="data-right__review">
                  <HTMLStringParser>
                    {currentProduct.review}
                  </HTMLStringParser>
                </div>
                <div className="data-right__possibilities">
                  <div className="data-right__possibilities--title">
                    {currentProduct.possibilitiesUnit}
                  </div>
                  <div className="data-right__possibilities--list">
                    {currentProduct.possibilities.split(',').map((possibility, index) => <div key={index} className="data-right__possibilities--list--element">{possibility}</div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {productStatus === PRODUCT_DETAIL_BY_ID_LOADING && <Spinner />}
          {currentProduct && currentProduct.details && <ProductDetails productDetails={currentProduct.details} />}
        </>
      )}
      <div className="product-page__bottom-section">
        <ThreeAdsSection  withTitle />
      </div>
    </div>
  );
}

ProductPage.propTypes = {
  match: PropTypes.object,
  products: PropTypes.object,
  getProductById: PropTypes.func,
  getProductDetailsById: PropTypes.func,
  categories: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(connect(({
  products,
  categories,
}) => ({
  products,
  categories,
}), {
  getProductById,
  getProductDetailsById,
})(ProductPage));
