import { supportedLocales } from 'Constants';

const initialState = {
  locale: supportedLocales.ro_RO,
}

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};
