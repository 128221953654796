const bold = '*';
const newParagraph = '\n';
const starSymbolRegex = /\\\*/g;
const escapeChar = '\\';

class DataParserService {
  static getParsedHtml(string) {
    let parsedString = '<p>';
    let boldStarted = false;
    const unescapedString = string.replace(newParagraph, "</p><p>");
    const unescapedStringArray = [...unescapedString];
    unescapedStringArray.forEach((char, index) => {
      let isEscaped = false;
      if (char === bold && unescapedStringArray[index - 1] && unescapedStringArray[index - 1] === escapeChar) {
        isEscaped = true;
      }
      switch (true) {
        case (char === bold && !boldStarted && !isEscaped):
          parsedString = parsedString.concat('<b>');
          boldStarted = true;
          break;
        case (char === bold && boldStarted && !isEscaped):
          parsedString = parsedString.concat('</b>');
          boldStarted = false;
          break;
        default:
          parsedString = parsedString.concat(char);
          break;
      }
    });
    parsedString = parsedString.concat('</p>');
    return parsedString.replace(starSymbolRegex, bold);
  }

  static getParameterByName(name, searchString) {
    name = name.replace(/[\\[\]]/g, '\\&$');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const decodedSearchString = decodeURIComponent(searchString)
    const results = regex.exec(decodedSearchString);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return results[2].replace(/\+/g, ' ');
  }
}

export default DataParserService;
