import React from 'react';
import PropTypes from 'prop-types';
import { useLocalized } from 'CustomHooks';

const DepartmentContact = ({
  contact: {
    title,
    Icon,
    phoneNumbers,
    email
  },
}) => {
  
  return (
      <div className="department-contact-element">
          {!!Icon && (<div className="department-contact-element__icon">
                  <Icon/>
              </div>
          )}
          <div className="department-contact-element__title">
              {useLocalized(title)}
          </div>
          {phoneNumbers && (
              <div className="department-contact-element__phone-numbers">
                  {phoneNumbers.map(({
                                         id,
                                         formattedPhone,
                                         phone,
                                     }) => (
                      <a href={`tel: ${phone}`} key={id} className="phone-number">
                          {formattedPhone}
                      </a>
                  ))}
              </div>
          )}
          {email && (
              <div className="department-contact-element__emails">
                  <a href={`mailto:${email}`} className="email">
                      {email}
                  </a>
              </div>
          )}
      </div>
  );
};

DepartmentContact.propTypes = {
  contact: PropTypes.object,
};

export default DepartmentContact;
