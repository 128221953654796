import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'Elements/Spinner/Spinner';

const ImagePresenter = ({ src, alt, className, shouldPresentMobile, ...props }) => {
  const imageContainerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [imageStyle, setImageStyle] = useState({});
  const handleImageLoad = useCallback(() => {
    setIsLoading(false);
    const { clientWidth: containerWidth, clientHeight: containerHeight } = imageContainerRef.current;
    const imageStyle = {
      maxWidth: containerWidth,
      maxHeight: containerHeight,
    };
    setImageStyle(imageStyle);
  }, [setIsLoading]);

  // Image is resized if screen is rotated on tablet
  useEffect(() => {
    handleImageLoad();
  }, [handleImageLoad, shouldPresentMobile]);

  return (
    <div className={`image-presenter ${className}`} ref={imageContainerRef} {...props}>
      {isLoading && <Spinner /> }
      <img
        src={src}
        alt={alt}
        className={`image-presenter__image ${isLoading ? 'hidden' : ''}`}
        style={imageStyle}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

ImagePresenter.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  shouldPresentMobile: PropTypes.bool,
};

export default ImagePresenter;
