import React, { useMemo } from 'react';
import ProptTypes from 'prop-types';
import { DataParserService } from 'Services';

const HTMLStringParser = ({ children }) => {
  const parseHtml = useMemo(() => DataParserService.getParsedHtml(children), [children]);
  return (
    <div dangerouslySetInnerHTML={{ __html: parseHtml }} />
  );
};

HTMLStringParser.propTypes = {
  children: ProptTypes.string,
}

export default HTMLStringParser;
