import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import CategoryBar from 'Components/CategoryBar/CategoryBar';
import ProductSectionHeader from 'Elements/ProductSectionHeader/ProductSectionHeader';
import LeftAdsSection from 'Components/LeftAdsSection/LeftAdsSection';
import CategoryList from 'Components/CategoryList/CategoryList';
import ProductGrid from 'Components/ProductGrid/ProductGrid';
import ProductSlider from 'Components/ProductSlider/ProductSlider';
import ThreeAdsSection from 'Components/ThreeAdsSection/ThreeAdsSection';
import Spinner from 'Elements/Spinner/Spinner';
import { useCategorySelectionLogic, useWebServiceWithId, useSetting } from 'CustomHooks';
import { getProductsByCategory, getProductBySearchText } from 'Redux/Actions';
import { LOADING, SUCCESS } from 'Redux/actionTypes';
import { promotionalProductsCategoryId, companyName } from 'Constants';
import { DataParserService } from 'Services';
import { CategoryTitleRefContext } from 'Elements/ScrollToTop/ScrollToTop';

const HomePage = ({
  shouldPresentMobile,
  windowWidth,
  structuredCategories,
  categories: {
    status: categoryStatus,
    data: categoryData,
  },
  products: {
    status: productsStatus,
    data: productsData,
  },
  brands: {
    data: brandsData,
  },
  getProductsByCategory,
  getProductBySearchText,
  location: {
    search,
  },
  match: {
    path,
    isExact,
    params: {
      categoryId,
    },
  },
  history,
}) => {
  const [mainCategories,, setCurrentMainCategoryId, presentedSubCategories] = useCategorySelectionLogic(structuredCategories);

  // Whenever the user navigates, the menu is hidden
  useEffect(() => {
    history.listen(() => {
      setCurrentMainCategoryId(null);
    });
  }, [history, setCurrentMainCategoryId]);

  const adsCategoryId = useSetting(promotionalProductsCategoryId);
  const [currentCategory, setCurrentCategory] = useState(null);
  useEffect(() => {
    if (categoryStatus !== SUCCESS || !adsCategoryId) {
      return;
    }
    if (categoryId) {
      const categoryForId = categoryData.find(({ id }) => id === categoryId);
      setCurrentCategory(categoryForId);
      return;
    }

    // If search params are available no category should be presented
    if (search) {
      setCurrentCategory(null);
      return;
    }

    // If no category is set (on the main page) the ads category is presented
    const categoryForId = categoryData.find(({ id }) => id === adsCategoryId);
    setCurrentCategory(categoryForId);
  }, [adsCategoryId, categoryData, categoryId, categoryStatus, search]);

  useEffect(() => {
    if (!search) {
      return;
    }
    const searchValue = DataParserService.getParameterByName('name', search);
    getProductBySearchText(searchValue);
  }, [getProductBySearchText, search]);

  const filteredBrandsData = useMemo(() => brandsData.filter(brand => brand && brand.highlighted === true), [brandsData]);

  const getCategoryId = () => {
    if (!currentCategory) {
      return null;
    }
    return currentCategory.id;
  };

  const getPageTitle = () => {
    if (path === "/" && isExact) {
      return companyName;
    }
    if (currentCategory) {
      return currentCategory.name;
    }
  };

  useWebServiceWithId(productsStatus, getProductsByCategory, getCategoryId());

  const [presentedProducts, setPresentedProducts] = useState([]);
  useEffect(() => {
    if (currentCategory && productsData) {
      const filteredProductsByCategory = productsData.filter(({ categoryId }) => categoryId === currentCategory.id);
      setPresentedProducts(filteredProductsByCategory);
      return;
    }
    if (search) {
      const searchValue = DataParserService.getParameterByName('name', search);
      const filteredProductsByName = productsData.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()));
      setPresentedProducts(filteredProductsByName);
    }
  }, [currentCategory, productsData, search]);

  const categoryTitleRef = useContext(CategoryTitleRefContext);

  return (
    <div className="home-page">
      <Helmet title={getPageTitle()} />
      <div className="home-page__navigation-section" onMouseLeave={() => setCurrentMainCategoryId(null)}>
        {!mainCategories && <Spinner />}
        {mainCategories && (
          <>
            {!shouldPresentMobile && <CategoryBar categoryItems={mainCategories} onCategorySelection={setCurrentMainCategoryId} />}
            {presentedSubCategories && !shouldPresentMobile ? <CategoryList subCategories={presentedSubCategories} onMouseLeave={() => setCurrentMainCategoryId(null) } /> : <ProductSlider />}
          </>
        )}
      </div>
      <ProductSectionHeader brands={filteredBrandsData} shouldPresentMobile={shouldPresentMobile} windowWidth={windowWidth} />
      <div className="home-page__promotions-section">
        {!shouldPresentMobile && <LeftAdsSection />}
        <div className="home-page__promotions-section--products-area" ref={categoryTitleRef}>
          {presentedProducts.length === 0 && productsStatus === LOADING && <Spinner />}
          {productsStatus === SUCCESS && (
            <ProductGrid
              productList={presentedProducts}
              category={currentCategory}
              shouldPresentMobile={shouldPresentMobile}
            />
          )}
        </div>
      </div>
      {shouldPresentMobile && <LeftAdsSection />}
      <ThreeAdsSection />
    </div>
  );
}

HomePage.propTypes = {
  structuredCategories: PropTypes.array,
  categories: PropTypes.object,
  getProductsByCategory: PropTypes.func,
  getProductBySearchText: PropTypes.func,
  products: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  brands: PropTypes.object,
  location: PropTypes.object,
  shouldPresentMobile: PropTypes.bool,
  windowWidth: PropTypes.number,
}

export default withRouter(connect(({
  categories,
  products,
  brands,
}) => ({
  categories,
  products,
  brands,
}), {
  getProductsByCategory,
  getProductBySearchText,
})(HomePage));
