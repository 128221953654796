import { callWebservice } from 'Controllers/WebController';
import { 
  CATEGORY_LOADING,
  CATEGORY_SUCCESS,
  CATEGORY_FAIL,
} from 'Redux/actionTypes';

export const getCategories = () => async (dispatch) => {
  dispatch({ type: CATEGORY_LOADING });
  try {
    let options = {
        method: 'GET',
        path: '/category',
    };

    const categories = await callWebservice(options);
    dispatch({
      type: CATEGORY_SUCCESS,
      payload: categories,
    });
  } catch(err) {
      dispatch({ type: CATEGORY_FAIL });
  }
};
