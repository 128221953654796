import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Spinner from 'Elements/Spinner/Spinner';
import { getBanners } from 'Redux/Actions';
import { useWebService } from 'CustomHooks';
import { LOADING, SUCCESS } from 'Redux/actionTypes';

const ProductSlider = ({
  banners: {
    status: bannersStatus,
    data: bannersData,
  },
  getBanners,
}) => {
  useWebService(bannersStatus, getBanners);

  const [sliderIndex, setSliderIndex] = useState(0);
  const handleSliderChange = useCallback((index) => {
    setSliderIndex(index);
  }, []);
  
  return (
    <div className="product-slider">
      { bannersStatus === LOADING && <Spinner />  }
      {bannersStatus === SUCCESS && bannersData.length > 0 && (
        <Carousel
          className="product-slider__caroussel"
          infiniteLoop
          selectedItem={sliderIndex}
          swipeable={bannersData.length > 1}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          swipeScrollTolerance={2}
          autoPlay={bannersData.length > 1}
          onChange={handleSliderChange}
        >
          {bannersData.map(({ id, image }) => <div key={id} className="product-slider__caroussel--element" style={{ backgroundImage: `url(${image})` }} />)}
        </Carousel>
      )}
      <div className="product-slider__buttons">
        {bannersData.length > 1 && bannersData.map(({ id }, index) => (
          <button onClick={() => setSliderIndex(index)} key={id} className={`product-slider__buttons--element ${index === sliderIndex ? "selected" : ""}`} />
        ))}
      </div>
    </div>
  );
};

ProductSlider.propTypes = {
  banners: PropTypes.object,
  getBanners: PropTypes.func,
}

export default connect(({
  banners,
}) => ({
  banners,
}), {
  getBanners,
})(ProductSlider);
