import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const ExpansionPanel = ({
  children,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpening = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className="expansion-panel" onClick={handleOpening}>
      <div className="expansion-panel__title">
        <div className="expansion-panel__title--text">
          {title}
        </div>
        <div className={`expansion-panel__title--icon ${isOpen ? "open" : ""}`}>
          <div className="icon left" />
          <div className="icon right" />
        </div>
      </div>
      <div className={`expansion-panel__body ${isOpen ? "open" : ""}`}>{children}</div>
    </div>
  );
};

ExpansionPanel.propTypes = {
  title: PropTypes.element,
  children: PropTypes.element,
};

export default ExpansionPanel;
