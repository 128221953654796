import { ContactService } from 'Services';
import {
  CONTACTS_LOADING,
  CONTACTS_SUCCESS,
  CONTACTS_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/actionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACTS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CONTACTS_SUCCESS:
      return {
        status: SUCCESS,
        data: ContactService.agentContactsToCountyContacts(payload),
      };
    case CONTACTS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};
