import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { magnifier } from 'Assets';
import { useLocalized } from 'CustomHooks';

const SearchBar = ({ searchHandler }) => {
  const [input, setInput] = useState('');
  const callSeachHandler = useCallback((e) => {
    searchHandler(input, e);
    if (e === undefined || e.key === 'Enter') {
      setInput('');
    }
  }, [input, searchHandler]);

  return (
    <div className="search-bar">
      <input
        className="search-bar__input" 
        type="text"
        id="search-bar-id"
        placeholder={useLocalized("search_here")}
        value={input}
        onChange={e => setInput(e.target.value)}
        onKeyPress={e => callSeachHandler(e)}
      />
      <button className="search-bar__magnifier-button" disabled={input === ""} onClick={() => callSeachHandler()}>
        <img
          className="search-bar__magnifier-button--image"
          id="magnifying-glass-id"
          src={magnifier}
          alt="magnifying glass"
        />
      </button>
    </div>
  );
}

SearchBar.propTypes = {
  searchHandler: PropTypes.func
};

export default SearchBar;
