import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkImage = ({ imageSrc, link, id, className, newTab }) => {
  return React.createElement(
    newTab ?  'a' : Link,
    newTab ? { href: link, target: '_blank' } : { to:  link },
    (<img id={id} className={className} src={imageSrc} alt="If you see this text, then you don't see the illustration..." />)
  );
}

LinkImage.propTypes = {
  imageSrc: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  link: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  newTab: PropTypes.bool,
};

export default LinkImage;
