import React from 'react';
import PropTypes from 'prop-types';
import { useLocalized } from 'CustomHooks';
import LinkWithState from 'Elements/LinkWithState/LinkWithState';

const AdBar = ({ id, title, backgroundImage, parentCategoryId }) => {
  return (
    <div className='ad-bar' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <LinkWithState to={`/category/${id}`} className="ad-bar__box--link" state={{ parentCategoryId }}>
        <div className="ad-bar__box">
          <div className="ad-bar__box--title">{title}</div>
            {`${useLocalized('discover_offers')} >>`}
        </div>
      </LinkWithState>
      <div className="side-ad-bar__shadow" />
    </div>
  );
};

AdBar.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  parentCategoryId: PropTypes.string,
}

export default AdBar;
