import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MagnifierContainer,
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers';
import ProductImageViewerBottom from 'Elements/ProductImageViewerBottom/ProductImageViewerBottom';

const ProductImageViewer = ({ images, productName }) => {
  const [bigImageSrc, setBigImageSrc] = useState(""); 
  const imgClickHandler = useCallback((imgSrc) => {
    setBigImageSrc(imgSrc);
  }, []);

  useEffect(() => {
    if (!bigImageSrc) {
      setBigImageSrc(images[0].image);
    }
  }, [bigImageSrc, images]);

  return (
    <div className="product-image-viewer">
      <MagnifierContainer className="product-image-viewer__big-image">
        <Magnifier
          imageSrc={bigImageSrc}
          mouseActivation={MOUSE_ACTIVATION.CLICK}
          touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
          cursorStyle="zoom-in"
          dragToMove={false}
        />
      </MagnifierContainer>
      {images.length > 1 && <ProductImageViewerBottom images={images} imgClickHandler={imgClickHandler} productName={productName} /> }
    </div>
  );
}

ProductImageViewer.propTypes = {
  images: PropTypes.array,
  productName: PropTypes.string,
}

export default ProductImageViewer;
