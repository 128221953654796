import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkLabel = ({ text, link, id, className }) => {
    return (
        <Link className="link-label" to={link}>
            <span id={id} className={className}>{text}</span>
        </Link>
    );
}

LinkLabel.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
};

export default LinkLabel;
