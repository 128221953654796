import { callWebservice } from 'Controllers/WebController';
import { 
  BANNERS_LOADING,
  BANNERS_SUCCESS,
  BANNERS_FAIL,
} from 'Redux/actionTypes';

export const getBanners = () => async (dispatch) => {
  dispatch({ type: BANNERS_LOADING });
  try {
    let options = {
        method: 'GET',
        path: '/mainbanner',
    };

    const banners = await callWebservice(options);
    dispatch({
      type: BANNERS_SUCCESS,
      payload: banners,
    });
  } catch(err) {
      dispatch({ type: BANNERS_FAIL });
  }
};
