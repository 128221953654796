import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocalized } from 'CustomHooks';
import { breakpoints } from 'Constants';

const scrollFrequency = 5000;
const timeoutFunction = (scrollableAreaRef, timerRef, numberOfItems, itemLength) => setTimeout(() => {
  if (scrollableAreaRef.current.scrollLeft + scrollableAreaRef.current.clientWidth >= numberOfItems * itemLength - 1) {
    scrollableAreaRef.current.scrollLeft = 0;
    timerRef.current = timeoutFunction(scrollableAreaRef, timerRef, numberOfItems, itemLength);
    return;
  }
  scrollableAreaRef.current.scrollLeft = scrollableAreaRef.current.scrollLeft + itemLength;
  timerRef.current = timeoutFunction(scrollableAreaRef, timerRef, numberOfItems, itemLength);
}, scrollFrequency);

const getNumberOfElementsPresented = () => {
  const windowWidth = window.innerWidth;
  switch (true) {
    case windowWidth > breakpoints.xl:
      return 8;
    case windowWidth > breakpoints.lg:
      return 4;
    case windowWidth > breakpoints.md:
      return 3;
    case windowWidth > breakpoints.sm:
      return 2;
    default:
      return 1;
  }
};

const ProductSectionHeader = ({
  brands,
  shouldPresentMobile,
  windowWidth,
}) => {
  const scrollableAreaRef = useRef();
  const itemLength = useRef();
  const timerRef = useRef();

  const [itemStyle, setItemStyle] = useState({});
  useEffect(() => {
    const currentItemLength = parseInt(scrollableAreaRef.current.clientWidth / getNumberOfElementsPresented());
    setItemStyle({
      minWidth: currentItemLength,
      width: currentItemLength,
    })
    itemLength.current = currentItemLength;
  }, [shouldPresentMobile, windowWidth]);

  // Whenever the screen is resized the scrolled content is reset
  useEffect(() => {
    if (!scrollableAreaRef.current) {
      return;
    }
    scrollableAreaRef.current.scrollLeft = 0;
  }, [windowWidth]);

  useEffect(() => {
    if (!itemLength.current) {
      return;
    }

    // Scrolling starts only if elements are longer then the space available
    if (brands.length * itemLength.current > scrollableAreaRef.current.clientWidth) {
      timerRef.current = timeoutFunction(scrollableAreaRef, timerRef, brands.length, itemLength.current);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [brands, scrollableAreaRef, itemLength, windowWidth]);

  return (
    <div className="product-section-header">
      <div className="product-section-header__title">
        {useLocalized('marketed_brands')}
      </div>
      <div className="product-section-header__list">
        <div
          className="product-section-header__list--scrollable-area"
          ref={scrollableAreaRef}
        >
          {brands.map(({
            id,
            name,
            logo,
          }) => (
            <div key={id} className="product-section-header__list--element" style={itemStyle}>
              <img src={logo} className="product-section-header__list--element--image" alt={name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ProductSectionHeader.propTypes = {
  brands: PropTypes.array,
  shouldPresentMobile: PropTypes.bool,
  windowWidth: PropTypes.number,
};

export default ProductSectionHeader;
