import { BrandService } from 'Services';
import {
  BRANDS_LOADING,
  BRANDS_SUCCESS,
  BRANDS_FAIL,
  MERGE_BRANDS_FROM_PRODUCTS,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/actionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRANDS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case BRANDS_SUCCESS:
      return {
        status: SUCCESS,
        data: payload,
      };
    case BRANDS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case MERGE_BRANDS_FROM_PRODUCTS:
      return {
        ...state,
        data: BrandService.mergeBrandsFromProducts(payload, state.data),
      };
    default:
      return state;
  }
};
