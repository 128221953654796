import {
  GuarantiesDepartmentIcon,
  MarketingDepartmentIcon,
  SalesEngrossIcon
} from 'Elements/Icons';

export const FACEBOOK_PAGE_URL = "https://www.facebook.com/magdolnaimpex";
export const promotionalProductsCategoryId = 'promotional_products_category';
export const leftAdCategoryId = 'ad_2_category';
export const bottomAdCategoryId = 'ad_1_category';
export const companyName = 'Magdolna';
export const jobApplicationMailAddress = "hr@magdolna.ro";
export const staticCompanyContacts = [
  {
    id: 1,
    title: 'empty_space'
  },
  {
    id: 2,
    title: 'engross',
    Icon: SalesEngrossIcon,
    email: 'office@magdolna.ro'
  },
  {
    id: 3,
    title: 'marketing_department',
    Icon: MarketingDepartmentIcon,
    email: 'marketing@magdolna.ro'
  },
  {
    id: 4,
    title: 'guaranties',
    Icon: GuarantiesDepartmentIcon,
    email: 'garantie@magdolna.ro'
  },
  {
    id: 5,
    title: 'empty_space'
  },
];
