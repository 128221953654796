import React from 'react';
import {
    AvatarIcon,
    HomeAvatarIcon,
    MailIcon,
    PhoneIcon,
} from 'Elements/Icons';
import PropTypes from "prop-types";

const ContactDetailsElement = ({currentContact}) => {
    return (
        <div>
            <div className="contact-detail">
                <div className="contact-detail__icon">
                    {!currentContact.defaultContact && <AvatarIcon/>}
                    {currentContact.defaultContact && <HomeAvatarIcon/>}
                </div>
                <div className="contact-detail__text">
                    {currentContact.agentName}
                </div>
            </div>
            {currentContact.description && (
                <div className="contact-detail">
                    <div className="contact-detail__text" dangerouslySetInnerHTML={{ __html: currentContact.description}} />
                </div>
            )}
            {currentContact.formattedPhone && (
                <div className="contact-detail">
                    <div className="contact-detail__icon">
                        <PhoneIcon/>
                    </div>
                    <a className="contact-detail__text" href={`tel: ${currentContact.phone}`}>
                        {currentContact.formattedPhone}
                    </a>
                </div>
            )}
            <div className="contact-detail">
                <div className="contact-detail__icon">
                    <MailIcon/>
                </div>
                <a className="contact-detail__text" href={`mailto: ${currentContact.email}`}>
                    {currentContact.email}
                </a>
            </div>
        </div>
    );
}

ContactDetailsElement.propTypes = {
    currentContact: PropTypes.object
}

export default ContactDetailsElement;
