import { useState, useEffect } from 'react';
import { useSetting } from 'CustomHooks';

const useCategoryAdSelectionLogic = (adCategoryId, categoryData) => {
  const categoryId = useSetting(adCategoryId);

  const [currentCategories, setCurrentCategories] = useState([]);
  useEffect(() => {
    if (!categoryId && !categoryData && categoryData.length === 0) {
      return;
    }
    const categoriesForId = categoryData.filter((category) => category.parentCategories && category.parentCategories.includes(categoryId));
    setCurrentCategories(categoriesForId);
  }, [categoryData, categoryId]);

  return [currentCategories, categoryId];
};

export default useCategoryAdSelectionLogic;
