import React from 'react';

const MailIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path d="M20.55,20.8s0,0,0,0Z" fill="#111224"/>
                    <path
                        d="M21,20.76c0-.05,0-.09,0-.14V6.93a.36.36,0,0,0-.15-.33L19.21,5.18a.39.39,0,0,1-.14-.32c0-1.5,0-3,0-4.51,0-.32,0-.35-.36-.35H5.5c-.09,0-.15,0-.2.11L2.05,3.36a.42.42,0,0,0-.13.32c0,.4,0,.81,0,1.22a.32.32,0,0,1-.13.27L.18,6.58A.46.46,0,0,0,0,7V20.66C0,21,0,21,.35,21H20.8c.12,0,.2-.06.19-.2Z"
                        fill="#fefefe"/>
                    <path
                        d="M5.54,0H18.72c.32,0,.36,0,.36.35,0,1.5,0,3,0,4.51a.39.39,0,0,0,.14.32L20.85,6.6a.36.36,0,0,1,.15.33V20.62c0,.05,0,.09,0,.14s0,0,0,0-.07.19-.19.2H.35C0,21,0,21,0,20.66V7a.46.46,0,0,1,.18-.39L1.8,5.17a.32.32,0,0,0,.13-.27c0-.41,0-.82,0-1.22a.42.42,0,0,1,.13-.32L5.3.12c0-.06.11-.13.2-.11S5.53,0,5.54,0ZM19.06,5.68c0,.71,0,1.41,0,2.11,0,0,0,0,0,0v.08s0,0,0,0,.09.06.13,0l1.18-1c.1-.08.09-.11,0-.19l-1.18-1s-.06-.08-.12-.06h0ZM1.94,8Q2,8,1.94,7.9s0,0,0-.07,0,0,0,0q0-1,0-1.92a.15.15,0,0,0,0-.22H1.87L.6,6.77c-.1.08,0,.11,0,.15L1.81,8S1.88,8,1.94,8Zm.93-4.64H5.12c.14,0,.18,0,.18-.18,0-.77,0-1.54,0-2.31,0,0,0-.1,0-.11L2.72,3.37h.11S2.86,3.41,2.87,3.38Zm9.33-3H5.91c-.16,0-.2,0-.2.19,0,1,0,1.92,0,2.87,0,.26-.06.32-.32.32H2.51c-.12,0-.17,0-.17.15,0,1.45,0,2.9,0,4.36a.27.27,0,0,0,.09.2l5.66,4.95c.08.07.12.07.2,0,.24-.22.5-.44.75-.65a2.22,2.22,0,0,1,2.93,0l.73.64c.09.08.14.08.23,0l5.61-4.9a.33.33,0,0,0,.12-.28V.62c0-.16,0-.21-.19-.21ZM.79,20.57H20.22l-.09-.08-8.37-7.32a1.81,1.81,0,0,0-2.53,0l-7,6.08C1.78,19.69,1.29,20.12.79,20.57ZM20.58,7.31l-.06.05-7.28,6.36c-.1.08-.08.12,0,.2l6.16,5.38,1.17,1ZM7.87,13.81.43,7.31v13Z"
                        fill="#2a3588"/>
                    <path
                        d="M12.2.42h6.27c.16,0,.19.05.19.21V8.25a.33.33,0,0,1-.12.28l-5.61,4.9c-.09.09-.14.09-.23,0L12,12.8A2.22,2.22,0,0,0,9,12.8c-.25.21-.51.43-.75.65-.08.07-.12.07-.2,0L2.43,8.5a.27.27,0,0,1-.09-.2c0-1.46,0-2.91,0-4.36,0-.14.05-.15.17-.15H5.4c.26,0,.32-.06.32-.32,0-1,0-1.91,0-2.87,0-.15,0-.19.2-.19ZM10.5,6h5.08a.19.19,0,0,0,.17-.2.18.18,0,0,0-.16-.2.43.43,0,0,0-.16,0h-10a.21.21,0,0,0-.18.21.18.18,0,0,0,.18.2H10.5Zm0,2.54h5.08a.18.18,0,0,0,.17-.18.19.19,0,0,0-.15-.22.49.49,0,0,0-.17,0H5.58a.47.47,0,0,0-.17,0,.18.18,0,0,0-.16.21.19.19,0,0,0,.18.19H10.5Z"
                        fill="#2a3588"/>
                    <path d="M.79,20.57c.5-.45,1-.88,1.49-1.31l7-6.08a1.81,1.81,0,0,1,2.53,0l8.37,7.32.09.08Z"
                          fill="#fefefe"/>
                    <path d="M20.58,7.31v13l-1.17-1-6.16-5.38c-.09-.08-.11-.12,0-.2l7.28-6.36Z" fill="#fefefe"/>
                    <path d="M7.87,13.81.43,20.32v-13Z" fill="#fefefe"/>
                    <path d="M2.83,3.38H2.72L5.31.78s0,.07,0,.11c0,.77,0,1.54,0,2.31,0,.14,0,.18-.18.18H2.83Z"
                          fill="#fefefe"/>
                    <path
                        d="M1.94,5.87q0,1,0,1.92v0s0,0,0,.07V8C1.88,8,1.85,8,1.81,8l-1.18-1c-.05,0-.13-.07,0-.15L1.87,5.66h.07Z"
                        fill="#fefefe"/>
                    <path
                        d="M19.06,7.79c0-.7,0-1.4,0-2.11v0c.06,0,.08,0,.12.06l1.18,1c.09.08.1.11,0,.19L19.19,8s-.1.12-.13,0V7.79Z"
                        fill="#fefefe"/>
                    <path d="M1.94,5.87V5.65A.15.15,0,0,1,1.94,5.87Z" fill="#111224"/>
                    <path d="M1.94,8V7.9Q2,8,1.94,8Z" fill="#111224"/>
                    <path d="M19.06,7.79v0S19,7.81,19.06,7.79Z" fill="#111224"/>
                    <path d="M1.94,7.83v0S2,7.82,1.94,7.83Z" fill="#111224"/>
                    <path d="M2.83,3.38h0S2.85,3.41,2.83,3.38Z" fill="#111224"/>
                    <path d="M19.07,5.65v0Z" fill="#111224"/>
                    <path d="M19.06,7.91v0S19,7.92,19.06,7.91Z" fill="#111224"/>
                    <path
                        d="M10.5,6H5.43a.18.18,0,0,1-.18-.2.21.21,0,0,1,.18-.21h10a.43.43,0,0,1,.16,0,.18.18,0,0,1,.16.2.19.19,0,0,1-.17.2H10.5Z"
                        fill="#fff"/>
                    <path
                        d="M10.5,8.5H5.43a.19.19,0,0,1-.18-.19.18.18,0,0,1,.16-.21.47.47,0,0,1,.17,0h9.85a.49.49,0,0,1,.17,0,.19.19,0,0,1,.15.22.18.18,0,0,1-.17.18H10.5Z"
                        fill="#fff"/>
                </g>
            </g>
        </g>
    </svg>
);

export default MailIcon;
