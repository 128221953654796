import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinkWithState from 'Elements/LinkWithState/LinkWithState';

const CategoryList = ({
  subCategories,
  onMouseLeave,
}) => {
  return (
    <div className="category-list" onMouseLeave={onMouseLeave}>
      {subCategories.map(({
        id: parentCategoryId,
        name,
        subCategories: subSubCategories,
      }) => (
        <div className="category-list__section" key={parentCategoryId}>
          <Link className="category-list__section--title" to={`/category/${parentCategoryId}`}>
            {`${name}`}
          </Link>
          <div className="category-list__section--sub-list">
            {subSubCategories && subSubCategories.map(({
              id,
              name,
            }) => (
              <LinkWithState key={id} className="sub-category-element" to={`/category/${id}`} state={{ parentCategoryId }}>
                {name}
              </LinkWithState>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

CategoryList.propTypes = {
  subCategories: PropTypes.array,
  onMouseLeave: PropTypes.func,
}

export default CategoryList;
