import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ProductButtonBackgroundIcon } from 'Elements/Icons';
import ImagePresenter from 'Elements/ImagePresenter/ImagePresenter';
import LinkWithState from 'Elements/LinkWithState/LinkWithState';
import { default_product } from 'Assets';
import { useLocalized } from 'CustomHooks';

const ProductGridElement = ({
  product: {
    id,
    name,
    previewImage,
  },
  categoryId,
  location: {
    search,
  },
  history: {
    location: {
      state,
    },
  },
  shouldPresentMobile,
}) => {
  const [navigationState, setNavigationState] = useState(null);
  useEffect(() => {
    if (state) {
      setNavigationState(state);
      return;
    }
    if (search) {
      setNavigationState({
        search,
      });
    }
  }, [search, state]);

  return (
    <LinkWithState className="grid-element" to={`${search ? `/product/${id}` : `/category/${categoryId}/product/${id}`}`} state={navigationState}>
      <div className="grid-element__top">
        <ImagePresenter src={previewImage || default_product} alt={name} shouldPresentMobile={shouldPresentMobile} />
      </div>
      <div className="grid-element__bottom">
        <div className="grid-element__bottom--label">{name}</div>
        <div className="grid-element__bottom--product-link">
          <ProductButtonBackgroundIcon className="product-link-icon" />
          <span className="product-link-text">{useLocalized('see_details')}</span>
          </div>
      </div>
    </LinkWithState>
  );
}

ProductGridElement.propTypes = {
  product: PropTypes.object,
  categoryId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  shouldPresentMobile: PropTypes.bool,
};

export default withRouter(ProductGridElement);
