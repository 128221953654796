import { callWebservice } from 'Controllers/WebController';
import {
  PRODUCTS_LOADING,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCT_BY_ID_LOADING,
  PRODUCT_BY_ID_SUCCESS,
  PRODUCT_BY_ID_FAIL,
  PRODUCTS_BY_CATEGORY_LOADING,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
  PRODUCT_DETAIL_BY_ID_LOADING,
  PRODUCT_DETAIL_BY_ID_SUCCESS,
  PRODUCT_DETAIL_BY_ID_FAIL,
  PRODUCTS_BY_SEARCHTEXT_LOADING,
  PRODUCTS_BY_SEARCHTEXT_SUCCESS,
  PRODUCTS_BY_SEARCHTEXT_FAIL,
  MERGE_BRANDS_FROM_PRODUCTS,
} from 'Redux/actionTypes';

export const getProducts = () => async (dispatch) => {
  dispatch({ type: PRODUCTS_LOADING });
  try {
      let options = {
          method: 'GET',
          path: '/product',
      };
  
      const products = await callWebservice(options);
      dispatch({
        type: PRODUCTS_SUCCESS,
        payload: products,
      });
      dispatch({
        type: MERGE_BRANDS_FROM_PRODUCTS,
        payload: products,
      });
  } catch(err) {
      dispatch({ type: PRODUCTS_FAIL });
  }
};

export const getProductsByCategory = (categoryId) => async (dispatch) => {
  dispatch({ type: PRODUCTS_BY_CATEGORY_LOADING });
  try {
      let options = {
          method: 'GET',
          path: `/category/${categoryId}/product`,
      };

      const products = await callWebservice(options);
      dispatch({
        type: PRODUCTS_BY_CATEGORY_SUCCESS,
        payload: {
          products,
          categoryId,
        },
      });
      dispatch({
        type: MERGE_BRANDS_FROM_PRODUCTS,
        payload: products,
      });
  } catch(err) {
      dispatch({ type: PRODUCTS_BY_CATEGORY_FAIL });
  }
};

export const getProductById = (productId, categoryId) => async (dispatch) => {
  dispatch({ type: PRODUCT_BY_ID_LOADING });
  try {
      let options = {
          method: 'GET',
          path: `/product/${productId}`,
      };
  
      const product = await callWebservice(options);
      dispatch({
        type: PRODUCT_BY_ID_SUCCESS,
        payload: {
          products: [product],
          categoryId,
        }
      });
      dispatch({
        type: MERGE_BRANDS_FROM_PRODUCTS,
        payload: [product],
      });
  } catch(err) {
      dispatch({ type: PRODUCT_BY_ID_FAIL });
  }
};

export const getProductDetailsById = (productId) => async (dispatch) => {
  dispatch({ type: PRODUCT_DETAIL_BY_ID_LOADING });
  try {
      let options = {
          method: 'GET',
          path: `/product/${productId}/productDetail/`,
      };

      const details = await callWebservice(options);
      dispatch({
        type: PRODUCT_DETAIL_BY_ID_SUCCESS,
        payload: {
          details,
          productId,
        },
      });
  } catch(err) {
      dispatch({ type: PRODUCT_DETAIL_BY_ID_FAIL });
  }
};

export const getProductBySearchText = (searchText) => async (dispatch) => {
  dispatch({ type: PRODUCTS_BY_SEARCHTEXT_LOADING });
  try {
      let options = {
          method: 'GET',
          path: `/product/?search=${searchText}`,
      };
  
      const products = await callWebservice(options);
      dispatch({
        type: PRODUCTS_BY_SEARCHTEXT_SUCCESS,
        payload: {
          products,
        },
      });
      dispatch({
        type: MERGE_BRANDS_FROM_PRODUCTS,
        payload: products,
      });
  } catch(err) {
      dispatch({ type: PRODUCTS_BY_SEARCHTEXT_FAIL });
  }
};
