import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const LinkWithState = ({
  history,
  className,
  to,
  state,
  children,
}) => {
  const handleNavigation = useCallback(() => {
    history.push({
      pathname: to,
      state,
    });
  }, [history, state, to]);

  return (
    <div onClick={handleNavigation} className={className}>
      {children}
    </div>
  );
};

LinkWithState.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  state: PropTypes.object,
  to: PropTypes.string,
};

export default withRouter(LinkWithState);
