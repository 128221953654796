import { callWebservice } from 'Controllers/WebController';
import { 
  JOBS_LOADING,
  JOBS_SUCCESS,
  JOBS_FAIL,
} from 'Redux/actionTypes';

export const getJobs = () => async (dispatch) => {
  dispatch({ type: JOBS_LOADING });
  try {
    let options = {
        method: 'GET',
        path: '/job',
    };

    const jobs = await callWebservice(options);
    dispatch({
      type: JOBS_SUCCESS,
      payload: jobs,
    });
  } catch(err) {
      dispatch({ type: JOBS_FAIL });
  }
};
