import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalized, useDragLogic } from 'CustomHooks';
import { about_us_page_img } from 'Assets';

const AboutUsPage = ({
  structuredCategories,
}) => {
  const [ref, onMouseMove, onMouseDown, onMouseUp, preventDragHandler, onMouseLeave] = useDragLogic();

  const [isOverFlowing, setIsOverFlowing] = useState(false);
  useEffect(() => {
    if (ref.current) {
      setIsOverFlowing(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref, structuredCategories]);

  return (
    <div className="about-us-page">
      <div className="about-us-page__section top">
        <span className="about-us-page__section--title-1">
          {useLocalized('about_us_page_title_1')}
        </span>
        {` - `}
        <span className="about-us-page__section--title-2">
          {useLocalized('about_us_page_title_2')}
        </span>
        <div className="about-us-page__section--text">
          {useLocalized('about_us_page_text_top')}
        </div>
      </div>
      <div className="about-us-page__image-section">
        <img src={about_us_page_img} className="about-us-page__image-section--image" alt="about-us" />
      </div>
      <div
        className={`about-us-page__categories-section ${isOverFlowing ? "overflowing" : ""}`}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {structuredCategories.map(({
          id,
          name,
          logo,
        }) => (
          <div
            key={id}
            className="about-us-page__categories-section--item"
            onDragStart={preventDragHandler}
          >
            <div className="image-holder">
              <img src={logo} alt={name} className="image-holder__img"/>
            </div>
            <div className="title">
              {name}
            </div>
          </div>
        ))}
      </div>
      <div className="about-us-page__section top">
        <div className="about-us-page__section--regular-highlighted-text">
          {useLocalized('about_us_page_text_top')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_1')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_2')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_3')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_4')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_5')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_6')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_7')}
          <span className="about-us-page__section--regular-highlighted-text">
            {useLocalized('brand_list')}
          </span>
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_8')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_9')}
        </div>
        <div className="about-us-page__section--regular-text">
          {useLocalized('about_us_page_text_10')}
        </div>
      </div>
    </div>
  );
};

AboutUsPage.propTypes = {
  structuredCategories: PropTypes.array,
};

export default AboutUsPage;
