import React from 'react';

const HomeAvatarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44.68" height="44.68" viewBox="0 0 44.68 44.68">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path d="M22.34,44.68A22.34,22.34,0,1,1,44.68,22.34,22.36,22.36,0,0,1,22.34,44.68Zm0-41.15A18.81,18.81,0,1,0,41.15,22.34,18.82,18.82,0,0,0,22.34,3.53Z" fill="#2a3588"/>
                    <g>
                        <path d="M11.63,33.65a.62.62,0,0,0,.62.66h7.41V28.23s-.1-1,.87-1h3.08a1,1,0,0,1,1.08,1l0,6.05h7.26a.77.77,0,0,0,.78-.82V22.26L22.49,13.17,11.63,22.26Z" fill="#2a3588"/>
                        <path d="M7.63,21.42s.93,1.7,2.94,0l12-10.17,11.26,10.1c2.33,1.68,3.2,0,3.2,0L22.58,8.25Z" fill="#2a3588"/>
                        <polygon points="33.57 11.23 30.67 11.23 30.69 14.74 33.57 17.19 33.57 11.23" fill="#2a3588"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default HomeAvatarIcon;
