import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group'; 
import CategoryLabel from 'Elements/CategoryLabel/CategoryLabel';
import SearchBar from 'Elements/SearchBar/SearchBar';
import LinkLabel from 'Elements/LinkLabel/LinkLabel';
import LinkImage from 'Elements/LinkImage/LinkImage';
import CategoryBar from 'Components/CategoryBar/CategoryBar';
import CategoryList from 'Components/CategoryList/CategoryList';
import MobileMenu from 'Components/MobileMenu/MobileMenu';
import { FACEBOOK_PAGE_URL } from 'Constants/companyOptions';
import { facebook_logo, logo } from 'Assets';
import { useLocalized, useCategorySelectionLogic } from 'CustomHooks';

const TopNavBar = ({
  history,
  location: {
    pathname,
  },
  structuredCategories,
  shouldPresentMobile,
}) => {
  const [isMenuPresented, setIsMenuPresented] = useState(false);
  const [isMobileMenuPresented, setIsMobileMenuPresented] = useState(false);
  const [mainCategories,, setCurrentMainCategoryId, presentedSubCategories] = useCategorySelectionLogic(structuredCategories);

  const shouldNotPresentMenu = useMemo(() => pathname === '/' || pathname.includes('search') || (pathname.includes('category') && !pathname.includes('product')), [pathname]);

  // Menu opening is available only on the main page, Mobile menu is presented on mobile screens
  const handleMenuOpening = useCallback(() => {
    if (shouldPresentMobile) {
      setIsMobileMenuPresented(!isMobileMenuPresented);
      return;
    }
    if (shouldNotPresentMenu) {
      return;
    }
    setIsMenuPresented(true);
  }, [isMobileMenuPresented, shouldNotPresentMenu, shouldPresentMobile]);

  useEffect(() => {

    // Prevents mobile menu staying on the screen while user rotates a tablet to the landscape mode
    if (!shouldPresentMobile) {
      setIsMobileMenuPresented(false);
    }

    // If user navigates to the main page, menu should be closed
    if (shouldNotPresentMenu) {
      setIsMenuPresented(false);
    }
  }, [shouldNotPresentMenu, shouldPresentMobile]);

  // Any time the user navigates the mobile menu should dissapear
  useEffect(() => {
    history.listen(() => {
      setIsMobileMenuPresented(false);
    });
  }, [history]);

  const searchHandler = useCallback((searchText, e) => {
    if ((e && e.key !== 'Enter') || searchText === "") {
      return;
    }
    history.push({
      pathname: '/search',
      search: `?name=${encodeURIComponent(searchText)}`,
    });
  }, [history]);

  return (
    <div className="top-nav-bar">
      <div className="top-nav-bar__top">
        <LinkImage id="magdolna-image-id" imageSrc={logo} className="top-navigation-icon" link="/"/>
        <div className="top-nav-bar__top--right-nav-elements">
          <LinkLabel className="top-nav-bar__top--right-nav-elements--component" link="/despre-noi" id="about-us-label-id" text={useLocalized('about_us')} />
          <LinkLabel className="top-nav-bar__top--right-nav-elements--component" link="/cookies-policy" id="cookie-policy-label-id" text={useLocalized('cookie_policy')} />
          <LinkLabel className="top-nav-bar__top--right-nav-elements--component" link="/terms-and-conditions" id="terms-and-conditions-label-id" text={useLocalized('terms_and_conditions')} />
        </div>
      </div>
      <div className="top-nav-bar__bottom">
          <CategoryLabel setIsMenuPresented={handleMenuOpening} shouldPresentMobile={shouldPresentMobile} isMobileMenuPresented={isMobileMenuPresented} />
          <TransitionGroup>
            {mainCategories && isMenuPresented && (
              <CSSTransition key="slided-menu" classNames="transition" timeout={200} in={isMenuPresented}>
                <div className="menu-container" onMouseLeave={() => setIsMenuPresented(false)}>
                  <CategoryBar categoryItems={mainCategories} onCategorySelection={setCurrentMainCategoryId} />
                  {presentedSubCategories && <CategoryList subCategories={presentedSubCategories} />}
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
          <SearchBar searchHandler={searchHandler} />
          <div className="top-nav-bar__bottom--right-nav-elements">
            <LinkLabel className="top-nav-bar__bottom--right-nav-elements--component" id="carier-label-id" text={useLocalized('jobs')} link="/cariere"/>
            <LinkLabel className="top-nav-bar__bottom--right-nav-elements--component" id="contact-label-id" text={useLocalized('contact')} link="/contact"/>
            <LinkImage
              className="top-nav-bar__bottom--right-nav-elements--component fb-image"
              id="fb-image-id"
              link={FACEBOOK_PAGE_URL}
              imageSrc={facebook_logo}
              newTab
            />
          </div>
          <TransitionGroup component={null}>
            {isMobileMenuPresented && (
              <CSSTransition key="slided-menu" classNames="transition" timeout={200} in={isMobileMenuPresented}>
                <MobileMenu structuredCategories={structuredCategories} />
              </CSSTransition>
            )}
          </TransitionGroup>
      </div>
    </div>
   );
}

TopNavBar.propTypes = {
  structuredCategories: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  shouldPresentMobile: PropTypes.bool,
};

export default withRouter(TopNavBar);
