
import { useState, useEffect, useMemo } from 'react';

const useCategorySelectionLogic = (categoryData) => {
  const [currentMainCategoryId, setCurrentMainCategoryId] = useState(null);
  const [presentedSubCategory, setPresentedSubcategory] = useState(null);

  const mainCategories = useMemo(() => {
    if (categoryData && categoryData.length > 0) {
      return categoryData.map(({
        id,
        name,
        visibleFromMenu,
        logo,
      }) => ({
        id,
        name,
        visibleFromMenu,
        logo,
      }))
    }
  }, [categoryData]);

  // Sets the default selected category
  useEffect(() => {
    if (categoryData && categoryData.length > 0 && !currentMainCategoryId) {
      setCurrentMainCategoryId(categoryData[0]);
    }
  }, [categoryData, currentMainCategoryId, setCurrentMainCategoryId]);

  // Whenever a new category is selected, changes the subcategories
  useEffect(() => {
    if (!categoryData) {
      return;
    }

    // Menu items are dissapearing when the user hovers out
    if (!currentMainCategoryId) {
      setPresentedSubcategory(null);
    }
    const selectedCategory = categoryData.find(category => category.id === currentMainCategoryId);
    if (selectedCategory) {
      setPresentedSubcategory(selectedCategory.subCategories);
    }
  }, [categoryData, currentMainCategoryId]);

  return [mainCategories, currentMainCategoryId, setCurrentMainCategoryId, presentedSubCategory];
};

export default useCategorySelectionLogic;
