import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import HTMLStringParser from 'Elements/HTMLStringParser/HTMLStringParser';
import { PdfIcon } from  'Elements/Icons';

const ProductDetails = ({ productDetails }) => {
  const [currentDetail, setCurrentDetail] = useState({});
  useEffect(() => {
    setCurrentDetail(productDetails[0])
  }, [productDetails]);
  
  const handleDetailSelection = useCallback((selectedDetailId) => {
    const selectedDetail = productDetails.find(({ id }) => selectedDetailId === id);
    setCurrentDetail(selectedDetail);
  }, [productDetails]);

  const sortedDetail = useMemo(() => productDetails.sort(({ order: orderA },{ order: orderB }) => orderA - orderB), [productDetails]);

  return (
    <div className="product-details">
      {currentDetail && (
        <>
          <div className="product-details__options-bar">
            {sortedDetail.map(({
              id,
              name,
            }) => (
              <button
                key={id}
                className={`product-details__options-bar--option ${id === currentDetail.id ? 'selected' : ''}`}
                onClick={() => handleDetailSelection(id)}
              >
                {name}
              </button>
            ))}
          </div>
          <div className="product-details__content">
            {currentDetail.text && (
              <div className="product-details__content--text">
                <HTMLStringParser>
                  {currentDetail.text}
                </HTMLStringParser>
              </div>
            )}
            {currentDetail.image && (
              <div className="product-details__content--image">
                <img src={currentDetail.image} alt={currentDetail.name} className="image" />
              </div>
            )}
            {currentDetail.files && currentDetail.files.length > 0 && (
              <div className="product-details__content--files">
                {currentDetail.files.map(({
                  id,
                  name,
                  url,
                }) => (
                  <a
                    key={id}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file"
                  >
                    <PdfIcon className="file__icon"/>
                    <div className="file__text">{name}</div>
                  </a>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ProductDetails.propTypes = {
  productDetails: PropTypes.array,
}

export default ProductDetails;
