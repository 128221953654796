import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdBar from 'Components/AdBar/AdBar';
import { useLocalized, useCategoryAdSelectionLogic } from 'CustomHooks';
import { bottomAdCategoryId } from 'Constants';

const ThreeAdsSection = ({
  withTitle,
  categories: {
    data: categoryData,
  }
}) => {
  const title = useLocalized('also_recommend');
  const [currentCategories, categoryId] = useCategoryAdSelectionLogic(bottomAdCategoryId, categoryData);
  return (
    <div className="tree-ads-section">
      {withTitle && <div className="tree-ads-section__title">{title}</div>}
      <div className="tree-ads-section__container">
        {currentCategories.map(({ id, name, logo }) => (
          <AdBar
            key={id}
            id={id}
            title={name}
            parentCategoryId={categoryId}
            backgroundImage={logo}
          />
        ))}
      </div>
    </div>
  );
};

ThreeAdsSection.propTypes = {
  withTitle: PropTypes.bool,
  categories: PropTypes.object,
};

export default connect(({
  categories,
}) => ({
  categories,
}), )(ThreeAdsSection);
