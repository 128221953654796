import {
  currentEnvironment,
  productionEnvironment,
  testEnvironment,
  developmentEnvironment,
  localEnvironment }
  from 'Constants';

const PROD_GTM_HEAD = '<!-- Google Tag Manager -->\n' +
  '<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
  'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
  'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
  '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
  '})(window,document,\'script\',\'dataLayer\',\'GTM-WW5H95L\');</script>\n' +
  '<!-- End Google Tag Manager -->'

const PROD_GTM_BODY = '<!-- Google Tag Manager (noscript) -->\n' +
  '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WW5H95L"\n' +
  'height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>\n' +
  '<!-- End Google Tag Manager (noscript) -->'

function configureGTM() {
  let gtmHead = null;
  let gtmBody = null;

  switch (currentEnvironment) {
    case testEnvironment:
    case productionEnvironment:
      gtmHead = PROD_GTM_HEAD
      gtmBody = PROD_GTM_BODY
      break;

    case developmentEnvironment:
    case localEnvironment:
    default:
      return
  }

  const gtmScriptHead = document.createRange().createContextualFragment(gtmHead);
  document.head.appendChild(gtmScriptHead);

  const gtmScriptBody = document.createRange().createContextualFragment(gtmBody);
  document.body.insertBefore(gtmScriptBody, document.body.firstChild);
}

export default configureGTM;
